import { Component, Vue, Prop } from "vue-property-decorator";
import { Bank } from "@/types/bank";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import { CardType } from "@/types/card";

@Component({
  inheritAttrs: false,
  components: {
    IssuePrepaidCardForm: () =>
      import(
        "../../../common/components/IssuePrepaidCardForm/IssuePrepaidCardForm.vue"
      ),
    IssueCardLayout: () =>
      import("../../layouts/IssueCardLayout/IssueCardLayout.vue"),
  },
})
export default class IssuePrepaidCard extends Vue {
  @profileModule.Getter("profileFullName")
  protected readonly profileFullNameGetter!: ProfileGetters["profileFullName"];

  @Prop({ type: Object })
  protected readonly selectedBank?: Bank;

  private get cardType() {
    return CardType.PREPAID;
  }
}
